import { postSavedSearchSaveSearch, QueryExpr } from '@apis/Resources';
import { useGetMapContractsGetMapContractById } from '@apis/TagManager';
import { MapContract } from '@apis/TagManager/model';
import styled from '@emotion/styled';
import { Box, Button, Loader, Text, useMantineTheme, Checkbox as MantineCheckbox } from '@mantine/core';
import { DataColumnConfig, DataGridState, GridColumnState } from '@root/Components/DataGrid/Models';
import { ResourceGridModel, ResourcesGrid } from '@root/Components/Resources/ResourcesGrid';
import { PageContent, PagePanel, PanelBody, PaneledPage, PanelToolbar } from '@root/Design/Layout';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useLink } from '@root/Services/Router/Router';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { MapTagEditorModel } from './Models';
import { MapTagEditorPanel } from './MapTagEditorPanel';
import { InlineEditTagPopover } from '@root/Components/Resources/Tags/InlineTagging';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import { BaseResource, IQueryExpr } from '@apis/Resources/model';
import { CustomColors } from '@root/Design/Themes';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { MapTagPickerOption, ResourceTypeMapTagPicker } from '../Components';
import { MapResourceQueryService } from '../../Services/MapResourceQueryService';
import { TagOption } from '../../Services/MapCostAllocationTagService';
import { AlertCircle, AlertTriangle, Checkbox, ChevronLeft, CircleCheck, QuestionMark, Trash } from 'tabler-icons-react';
import { MapContractsEvents } from '../../Services/MapContractsEvents';
import { FormatService } from '@root/Services/FormatService';
import { addYears } from 'date-fns';
import { TooltipWhite } from '@root/Design/Primitives';

export function MapTagExplorerPage() {
    const { getData } = useNav();
    const { filter: rawFilter, id: rawContractId, returnTo } = getData('filter', 'id', 'returnTo');
    const filter = useMemo(() => (rawFilter ? JSON.parse(rawFilter) : undefined), [rawFilter]);
    const id = parseInt(rawContractId ?? '0');
    const { data: contract, isLoading } = useGetMapContractsGetMapContractById({ id });

    return isLoading || !contract ? <></> : <MapTagExplorer returnTo={returnTo} filter={filter} contract={contract} />;
}

export function MapTagExplorer({ filter, contract, returnTo }: { filter?: QueryExpr[]; contract: MapContract; returnTo?: string }) {
    const { getAscendUrl } = useNav();
    const theme = useMantineTheme();
    const di = useDiContainer();
    const fmtSvc = useDi(FormatService);
    const contractEvts = useDi(MapContractsEvents);
    const model = useMemo(() => di.resolve(MapTagEditorModel).init(contract), [contract]);
    const [gridModel, setGridModel] = useState<ResourceGridModel>();
    const defaultCriteria = useMemo(
        () => ({ Operation: 'eq', Operands: [{ Field: 'ManagementAccount' }, { value: contract.AccountIds ?? [''] }] } as IQueryExpr),
        [contract]
    );

    const link = useLink();
    const columnToAdd = {
        header: 'MAP Eligiblity',
        defaultWidth: 150,
        id: 'mapeligibility',
        noSort: true,
        noRemove: true,
        noReorder: true,
        cellRenderer: (item) => {
            const eligibleResourceTypes = model.mapQuerySvc.validResourceTypes == undefined ? [] : model.mapQuerySvc.validResourceTypes;
            const createDate = fmtSvc.toLocalDate(item.CreateDate);
            const parentCreateDate = fmtSvc.toLocalDate(item.ParentCreateDate);
            const isEligibleCreateDate =
                !item.CreateDate || (createDate >= model.mapQuerySvc.contractFrom && createDate <= model.mapQuerySvc.contractTo);
            const isEligibleParentCreateDate = parentCreateDate >= model.mapQuerySvc.contractFrom && parentCreateDate <= model.mapQuerySvc.contractTo;
            const isEligibleResourceType = eligibleResourceTypes.includes(item.ResourceType!);
            var labelText: ReactNode = (
                <Box>
                    <Text size="sm">
                        {!item.ParentCreateDate ? null : isEligibleParentCreateDate ? (
                            <>
                                <CircleCheck color={theme.colors.success[6]} style={{ marginBottom: '-5px' }} />
                                &nbsp;&nbsp; Parent resource was created during MAP contract period
                            </>
                        ) : (
                            <>
                                <AlertTriangle color={theme.colors.warning[6]} style={{ marginBottom: '-5px' }} />
                                &nbsp;&nbsp; Parent resource was not created during MAP contract period
                            </>
                        )}
                    </Text>
                    <br />
                    <Text size="sm">
                        {!item.CreateDate ? (
                            <>
                                <QuestionMark color={theme.colors.gray[6]} style={{ marginBottom: '-5px' }} />
                                &nbsp;&nbsp; Create Date not available for MAP Eligibility check
                            </>
                        ) : isEligibleCreateDate ? (
                            <>
                                <CircleCheck color={theme.colors.success[6]} style={{ marginBottom: '-5px' }} />
                                &nbsp;&nbsp; Resource was created during MAP contract period
                            </>
                        ) : (
                            <>
                                <AlertTriangle color={theme.colors.warning[6]} style={{ marginBottom: '-5px' }} />
                                &nbsp;&nbsp; Resource was not created during MAP contract period
                            </>
                        )}
                    </Text>
                    <br />
                    <Text size="sm">
                        {isEligibleResourceType ? (
                            <>
                                <CircleCheck color={theme.colors.success[6]} style={{ marginBottom: '-5px' }} />
                                &nbsp;&nbsp; Service Eligible for MAP
                            </>
                        ) : (
                            <>
                                {' '}
                                <AlertTriangle color={theme.colors.warning[6]} style={{ marginBottom: '-5px' }} />
                                &nbsp;&nbsp; Service Ineligible for MAP
                            </>
                        )}
                    </Text>
                </Box>
            );

            return (
                <TooltipWhite withinPortal label={labelText}>
                    <Text style={{ marginTop: '5px' }}>{isEligibleCreateDate && isEligibleResourceType ? 'Eligible' : 'Ineligible'}</Text>
                </TooltipWhite>
            );
        },
    } as DataColumnConfig<BaseResource>;

    const [defaultColumns, setDefaultColumns] = useState<GridColumnState[]>([
        { id: 'Base.Name', width: 250, fixed: true },
        { id: 'Base.ResourceType', width: 180, fixed: true },
        { id: 'mapeligibility', width: 140 },
        { id: 'Base.CreateDate', width: 120 },
        { id: 'Tags.CsTags.map-migrated', width: 150 },
        { id: 'Tags.CsTags.map-workload', width: 150 },
        { id: 'Base.Account', width: 150 },
        { id: 'Base.Region', width: 100 },
    ]);

    useEvent(gridModel?.dataGrid?.selectionChanged);
    useEffect(() => {
        const listeners = [
            gridModel?.tagJobListener.jobsCompleted.listen(() => contractEvts.onTagChanged.emit()),
            gridModel?.inlineTagEditSvc.onTagChanged.listen(() => contractEvts.onTagChanged.emit()),
        ];

        return () => {
            listeners.forEach((l) => l?.dispose());
        };
    }, [gridModel]);
    const state = useMemo(() => {
        return {
            sort: [],
            columns: defaultColumns,
            filters: filter ?? [],
        } as DataGridState;
    }, [filter]);
    useEffect(() => {
        model.selection = gridModel?.selections;
    }, [gridModel?.selections]);

    const handleColumnsLoaded = (grid: ResourceGridModel) => {
        grid.availableColumns.push(columnToAdd);
        grid.availableColumns.forEach((col) => {
            if (col.id.startsWith('Tags.')) {
                const tagKey = col.id.replace(/Tags\.CsTags\./, '');
                if (tagKey === 'map-migrated') {
                    col.groupName = 'MAP Tag';
                    col.cellRenderer = (item) => (
                        <MapMigratedTagCell hideCredits={!model.creditsEnforced} querySvc={model.mapQuerySvc} item={item} tag={tagKey} grid={grid} />
                    );
                } else if (tagKey === 'map-workload') {
                    col.groupName = 'MAP Tag';
                    col.cellRenderer = (item) => (
                        <MapWorkloadTagCell hideCredits={!model.creditsEnforced} querySvc={model.mapQuerySvc} item={item} tag={tagKey} grid={grid} />
                    );
                } else {
                    col.cellRenderer = (item) => <NonInteractiveTagCell item={item} tag={tagKey} grid={grid} />;
                }
            }
        });
        grid.groupConfig['MAP Tag'] = { color: theme.colors.primary[3] };
        grid.groupConfig['Tags'] = { color: theme.colors.gray[4] };
    };

    const tagJobStarting = useCallback(async () => {
        const query = gridModel?.getQuery();
        if (query && !gridModel?.isFilterPaused() && !gridModel?.dataGrid?.hasGroupBy()) {
            if (filter) {
                const savedSearch = await postSavedSearchSaveSearch({ Where: query.where, Sort: query.sort });
                if (savedSearch) {
                    gridModel?.pauseFilter(savedSearch);
                }
            }
        }
    }, [gridModel]);

    const loading = useEventValue(model.loading);

    return (
        <PageContent>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelBody>
                        {loading ? (
                            <Box sx={{ textAlign: 'center' }} py="300px">
                                <Loader />
                            </Box>
                        ) : (
                            <ResourcesGrid
                                title="Resource Browser"
                                onModelLoaded={setGridModel}
                                defaultColumns={defaultColumns}
                                onColumnsLoaded={handleColumnsLoaded}
                                showRefresh
                                defaultState={state}
                                defaultCriteria={defaultCriteria}
                                leftFilterPlaceHolder={
                                    <Button
                                        component="a"
                                        {...link(getAscendUrl())}
                                        variant="filled"
                                        size="xs"
                                        my={5}
                                        sx={{ height: '30px' }}
                                        leftIcon={<ChevronLeft size={16} />}
                                        mr="xs"
                                        radius="lg"
                                    >
                                        Back
                                    </Button>
                                }
                                persistenceKey={state.filters.length ? undefined : 'Map Tag Explorer '}
                            />
                        )}
                    </PanelBody>
                </PagePanel>
                <PagePanel size={450}>
                    <PanelToolbar>
                        {returnTo && (
                            <Button hidden component="a" {...link(getAscendUrl())} variant="filled">
                                Return to "{returnTo}"
                            </Button>
                        )}
                    </PanelToolbar>
                    <PanelBody style={{ paddingLeft: 0, paddingTop: 0 }}>
                        {gridModel?.dataGrid && gridModel?.selections.count() > 0 ? (
                            <MapTagEditorPanel
                                onTagJobStarting={tagJobStarting}
                                selectionChanged={gridModel.dataGrid?.selectionChanged!}
                                selection={gridModel?.selections}
                                model={model}
                            />
                        ) : (
                            <EmptySpace>
                                <Text color="dimmed">Select resources on the left to assign "map-migrated" tag values. </Text>
                            </EmptySpace>
                        )}
                    </PanelBody>
                </PagePanel>
            </PaneledPage>
        </PageContent>
    );
}

function MapMigratedTagCell({
    item,
    tag,
    hideCredits,
    requireWorkload,
    grid,
    querySvc,
}: {
    item: BaseResource;
    tag: string;
    hideCredits?: boolean;
    requireWorkload?: boolean;
    grid: ResourceGridModel;
    querySvc: MapResourceQueryService;
}) {
    const evt = useMemo(() => EventEmitter.empty(), []);
    const theme = useMantineTheme();
    const value = item?.CsTags?.[tag];
    const styles = !value ? { fontStyle: 'italic', color: theme?.colors?.gray?.[6] as CustomColors } : undefined;
    useEvent(evt);
    const [confirmed, setConfirmed] = useState<boolean>(!requireWorkload);

    return (
        <InlineEditTagPopover
            grid={grid}
            hideCredits={hideCredits}
            popoverMode="side"
            item={item}
            tag={tag}
            onChange={() => evt.emit()}
            allowDelete={false}
            disableApply={!confirmed}
            renderInput={(mode, value, setValue) => (
                <>
                    <ResourceTypeMapTagPicker querySvc={querySvc} onSelectInline={setValue} selected={value} size="sm" type={[item.ResourceType!]} />
                    <MapTagPickerOption
                        Icon={Trash}
                        description={'Remove the "map-migrated" tag'}
                        value="Delete"
                        selected={mode === 'delete'}
                        onClick={() => setValue(null)}
                        size="sm"
                    />

                    <>
                        <MantineCheckbox
                            size="md"
                            style={{ marginTop: '12px' }}
                            checked={confirmed}
                            onChange={(event) => setConfirmed(event.target.checked)}
                            label={
                                <Text color="gray.5" size="xs">
                                    I confirm the selected workloads are included in my MAP Migration Plan.
                                </Text>
                            }
                            data-atid="WorkloadCheckboxInline"
                        />
                    </>
                </>
            )}
        >
            <GridFullCell style={{ ...styles, cursor: 'pointer' }} className="selector">
                {value === null ? <></> : value === undefined ? <>« No Tag »</> : value === '' ? <>« Empty »</> : <VisibleSpaces value={value} />}
            </GridFullCell>
        </InlineEditTagPopover>
    );
}

function MapWorkloadTagCell({
    item,
    tag,
    hideCredits,
    grid,
    querySvc,
}: {
    item: BaseResource;
    tag: string;
    hideCredits?: boolean;
    grid: ResourceGridModel;
    querySvc: MapResourceQueryService;
}) {
    const evt = useMemo(() => EventEmitter.empty(), []);
    const theme = useMantineTheme();
    const value = item?.CsTags?.[tag];
    const styles = !value ? { fontStyle: 'italic', color: theme?.colors?.gray?.[6] as CustomColors } : undefined;
    useEvent(evt);
    const [confirmed, setConfirmed] = useState<boolean>(false);

    return (
        <InlineEditTagPopover
            grid={grid}
            hideCredits={hideCredits}
            item={item}
            tag={tag}
            onChange={() => evt.emit()}
            allowDelete={false}
            disableApply={!confirmed}
            renderInput={(mode, value, setValue) => (
                <>
                    <ResourceTypeMapTagPicker querySvc={querySvc} onSelectInline={setValue} selected={value} size="sm" type={[item.ResourceType!]} />
                    <MantineCheckbox
                        size="md"
                        style={{ marginTop: '12px' }}
                        checked={confirmed}
                        onChange={(event) => setConfirmed(event.target.checked)}
                        label={
                            <Text color="gray.5" size="xs">
                                I confirm the selected workloads are included in my MAP Migration Plan.
                            </Text>
                        }
                    />
                </>
            )}
        >
            <GridFullCell style={{ ...styles, cursor: 'pointer' }} className="selector">
                {value === null ? <></> : value === undefined ? <>« No Tag »</> : value === '' ? <>« Empty »</> : <VisibleSpaces value={value} />}
            </GridFullCell>
        </InlineEditTagPopover>
    );
}

function NonInteractiveTagCell({ item, tag, grid }: { item: BaseResource; tag: string; grid: ResourceGridModel }) {
    const theme = useMantineTheme();
    const value = item?.CsTags?.[tag];
    const styles = !value ? { fontStyle: 'italic', color: theme?.colors?.gray?.[6] as CustomColors } : undefined;

    return (
        <GridFullCell style={{ ...styles }} className="selector">
            {value === null ? <></> : value === undefined ? <>« No Tag »</> : value === '' ? <>« Empty »</> : <VisibleSpaces value={value} />}
        </GridFullCell>
    );
}

const EmptySpace = styled.div`
    height: 100%;
    border-radius: 6px;
    background: ${(props) => props.theme.colors.gray[2]};
    display: flex;
    align-items: center;
    padding: 50px;
    justify-content: center;
`;

endpoint('map-resource-browser', MapTagExplorerPage, 'Resource Browser');
