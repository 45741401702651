import { Popover, TextInput, ActionIcon, Loader, Text, Portal, Overlay } from '@mantine/core';
import { useReadonlyInputStyles } from '@root/Design/Primitives';
import { useToggle } from '@root/Services/EventEmitter';
import { useState, useEffect, useCallback, useMemo, ReactNode, useRef, MouseEventHandler } from 'react';
import { ChevronUp, ChevronDown } from 'tabler-icons-react';
import { useRouteBoundPortal } from '../Router/RouteBoundPortal';
import { VirtualTree } from '../VirtualTree';
import { VirtualTreeModel } from '../VirtualTree/VirtualTreeModel';
import { FlyoverOverlay } from './Flyover';
import { Picker, PickerProps } from './Picker';

type ItemType = { value: string; label?: ReactNode };
type InternalItemType = { value: string; lcValue: string; label?: ReactNode };
export function Combobox({
    value,
    onChange,
    options,
    disabled,
    inputProps,
    popoverProps,
    pickerProps,
    pickerHeader,
}: {
    value: string;
    onChange: (value: string) => void;
    options: (filter: string) => Promise<ItemType[]>;
    disabled?: boolean;
    inputProps?: React.ComponentPropsWithoutRef<typeof TextInput>;
    popoverProps?: Partial<React.ComponentPropsWithoutRef<typeof Popover>>;
    pickerProps?: Partial<Omit<PickerProps<ItemType>, 'items' | 'onChange'>>;
    pickerHeader?: ReactNode;
}) {
    const [loading, setLoading] = useState<boolean>(false);
    const [availableValues, setAvailableValues] = useState<ItemType[]>([]);
    const [opened, { close, toggle, open }] = useToggle(false);
    const [filterText, setFilterText] = useState('');
    const [tree, setTree] = useState<VirtualTree>();
    const [treeModel, setTreeModel] = useState<VirtualTreeModel<InternalItemType>>();
    const input = useRef<HTMLInputElement>(null);
    const dropdown = useRef<HTMLDivElement>(null);

    const loadOptions = async () => {
        setLoading(true);
        try {
            const values = await options(filterText);
            setAvailableValues(values);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadOptions();
    }, [options]);

    const onDropdownClick = useCallback((evt: MouseEvent) => {
        evt.preventDefault();
        if (input.current) {
            input.current.focus();
        }
    }, []);

    const handleValuePicklistSelect = useCallback(
        (items: unknown[]) => {
            const typedItems = items as { value: string }[];
            if (typedItems.length) {
                onChange(typedItems[0].value);
                close();
                if (dropdown.current) {
                    dropdown.current.removeEventListener('click', onDropdownClick);
                }
            }
        },
        [close, onChange]
    );

    const openPicker = useCallback(() => {
        if (dropdown.current) {
            dropdown.current.addEventListener('click', onDropdownClick);
        }
        setFilterText('');
        open();
    }, [open, setFilterText, dropdown]);

    const handleValueInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            onChange(value);
            setFilterText(value);
        },
        [close, onChange]
    );

    const finalPickerProps = useMemo(
        () =>
            ({
                nameAccessor: ({ value }: { value: string }) => value,
                selections: [],
                renderItem: (item: (typeof filteredValues)[0]): ReactNode => {
                    return item.label ?? item.value;
                },
                noFilter: true,
                minimizeHeight: true,
                height: 300,
                mode: 'single' as const,
                ...pickerProps,
                onModelLoaded: (model, tree) => {
                    setTree(tree);
                    setTreeModel(model);
                },
            } as Omit<PickerProps<ItemType & { lcValue: string }>, 'items' | 'onChange'>),
        [pickerProps]
    );

    const values = useMemo(() => availableValues.map((x) => ({ ...x, lcValue: x.value.toLocaleLowerCase() })), [availableValues]);
    const filteredValues = useMemo(() => {
        const lcTagValue = filterText.toLowerCase();
        return values.filter((value) => value.lcValue.toLowerCase().includes(lcTagValue));
    }, [filterText, values]);

    const {
        classes: { readonly },
    } = useReadonlyInputStyles();

    const target = useRouteBoundPortal();

    const finalPopoverProps = useMemo(
        () =>
            ({
                position: 'bottom',
                offset: 0,
                shadow: 'md',
                withinPortal: true,
                zIndex: 1000,
                target,
                ...popoverProps,
                onClose: close,
            } as React.ComponentPropsWithoutRef<typeof Popover>),
        [close, popoverProps]
    );

    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
        (evt) => {
            if (evt.key === 'ArrowDown' || evt.key === 'ArrowUp') {
                treeModel?.navigate(evt.key.substring(5));
                const highlightedItem = treeModel?.getHighlightedItem();
                if (highlightedItem) {
                    tree?.scrollToItem(highlightedItem);
                    onChange(highlightedItem.value);
                }
            } else if (evt.key === 'Enter') {
                close();
                evt.preventDefault();
                evt.stopPropagation();
            } else if (evt.key === 'Tab') {
                close();
            } else if (evt.key === 'Escape') {
                close();
            }
        },
        [treeModel, tree]
    );

    return (
        <>
            <FlyoverOverlay onClick={close} opened={opened} />
            <Popover {...finalPopoverProps} opened={opened} onClose={close}>
                <Popover.Dropdown sx={{ '.vtree-container': { overflowY: 'scroll' }, borderColor: '#0003' }} p={0}>
                    <div ref={dropdown} style={{ display: 'contents' }}>
                        {pickerHeader}
                        <Picker {...finalPickerProps} items={filteredValues} onChange={handleValuePicklistSelect} />
                        {filteredValues.length ? null : (
                            <Text size="sm" color="dimmed" py="lg" align="center" italic>
                                Empty
                            </Text>
                        )}
                    </div>
                </Popover.Dropdown>
                <Popover.Target>
                    <TextInput
                        autoComplete="off"
                        rightSection={
                            loading ? (
                                <Loader size={16} />
                            ) : (
                                <ActionIcon
                                    variant={opened ? 'default' : undefined}
                                    color={opened ? 'primary' : undefined}
                                    tabIndex={-1}
                                    onClick={toggle}
                                >
                                    {opened ? <ChevronUp strokeWidth={3} size={20} /> : <ChevronDown strokeWidth={1} size={18} />}
                                </ActionIcon>
                            )
                        }
                        disabled={disabled}
                        className={disabled ? readonly : undefined}
                        {...inputProps}
                        ref={input}
                        onKeyDown={onKeyDown}
                        onChange={handleValueInputChange}
                        value={value}
                        onFocus={openPicker}
                    />
                </Popover.Target>
            </Popover>
        </>
    );
}
