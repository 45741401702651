import { QueryExpr } from '@apis/Resources';
import { QuerySelectExpr } from '@apis/Resources/model';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { PrimitiveType, traverseExpr, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { useEffect, useMemo, useState } from 'react';
import { QueryDatasource } from '../DashboardLayout/Models';
import { DataGrid } from '../DataGrid';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { ColumnConfig, DataGridState, DataSourceConfig } from '../DataGrid/Models';
import { operationLookup } from '../Filter/Services';
import { StandardChartProps } from './Models';

interface DatasourceHash {
    datasource: QueryDatasource | null;
    hash: string;
}
class GridChartModel {
    private columnsHash = '';
    private datasourceHash: DatasourceHash = { datasource: null, hash: '' };
    private loadedColumns: GridChartColumn[] = [];
    private loadedDatasource!: QueryDatasource;
    public columns?: ColumnConfig<Record<string, any>>[];
    public datasource?: DataSourceConfig<Record<string, any>>;
    public globalFilters: QueryExpr[] = [];
    public dataGrid?: DataGridModel;
    public viewInvalidated = EventEmitter.empty();
    public state?: DataGridState;
    public key = 0;
    public onStateChange?: (state: DataGridState) => void;

    public constructor(private fmtSvc: FormatService) {}

    public loadState(state: DataGridState) {
        this.state = state;
    }
    public saveState = (state: DataGridState) => {
        this.state = state;
        this.onStateChange?.(state);
    };
    public update(settings: GridChartSettings, datasource: QueryDatasource) {
        const { columns, state } = settings;
        this.state = state;
        this.updateColumns(columns);
        this.updateDatasource(columns, datasource);
    }
    public loadGrid = (dataGrid: DataGridModel) => {
        this.dataGrid = dataGrid;
    };
    public updateGlobalFilters(globalFilters: QueryExpr[]) {
        this.globalFilters = globalFilters;
        this.updateDatasource(this.loadedColumns, this.loadedDatasource);
        this.dataGrid?.viewInvalidated.emit();
    }
    private updateColumns(columns: GridChartColumn[]) {
        const nextHash = this.hashColumns(columns);
        if (nextHash !== this.columnsHash) {
            this.columns = this.adaptColumns(columns);
            this.updateColumnState(this.columns);
            this.columnsHash = nextHash;
            this.raiseViewInvalidated();
        }
        this.loadedColumns = columns;
    }
    private adaptColumns(columns: GridChartColumn[]) {
        return columns.map((c, i) => ({
            accessor: c.id ?? '',
            id: c.id,
            header: c.select.Alias ?? '',
            defaultWidth: 160,
            align: c.type === 'number' ? 'right' : c.type === 'boolean' ? 'center' : 'left',
            filter:
                c.type === 'unknown'
                    ? null
                    : {
                          filterType: c.type,
                          filterField: c.id ?? '',
                          name: c.select.Alias ?? '',
                          options:
                              c.type !== 'string'
                                  ? undefined
                                  : {
                                        getValueProvider: (col) => {
                                            return this.datasource instanceof Array
                                                ? (filter) => {
                                                      const data = this.datasource instanceof Array ? this.datasource : [];
                                                      const uniqueValues = new Set<string>();
                                                      data.forEach((item) => {
                                                          uniqueValues.add(item[c.id] as string);
                                                      });
                                                      const values = [...uniqueValues].filter((x) =>
                                                          !filter ? true : x?.toString().toLowerCase().includes(filter.toLowerCase())
                                                      );
                                                      values.sort((a, b) =>
                                                          a?.toString().localeCompare(b?.toString() ?? '', undefined, { sensitivity: 'base' })
                                                      );
                                                      return values;
                                                  }
                                                : undefined;
                                        },
                                    },
                      },
            type: c.type,
            formatter: c.formatter
                ? c.formatter
                : c.type === 'date'
                ? (v) => (typeof v[c.id] === 'number' ? new Date(v[c.id]).toDateString() : v[c.id])
                : !c.select.Expr || !('Operation' in c.select.Expr) || c.select.Expr.Operation !== 'percent'
                ? this.createDefaultFormatter(c)
                : (v) => (typeof v[c.id] === 'number' ? v[c.id] * 100 : 0).toFixed(0) + '%',
        })) as ColumnConfig<Record<string, any>>[];
    }

    private createDefaultFormatter = (col: GridChartColumn) => {
        return (item: Record<string, any>) => {
            const value = item[col.id];
            if (typeof value === 'number') {
                if (value % 1 !== 0) {
                    return this.fmtSvc.formatDecimal2(value);
                } else {
                    return this.fmtSvc.formatInt(value);
                }
            }
            return value;
        };
    };

    private updateColumnState(columns: ColumnConfig<Record<string, any>>[]) {
        const stateCols = this.state?.columns?.slice();
        if (stateCols?.length) {
            const colLookup = new Map(columns.map((c) => [c.id, c]));
            const stateLookup = new Map(stateCols?.map((c) => [c.id, c]));
            for (const col of columns) {
                if (!stateLookup.has(col.id)) {
                    stateCols.push({ id: col.id, width: col.defaultWidth });
                }
            }
            for (let i = stateCols.length - 1; i >= 0; i--) {
                const col = stateCols[i];
                if (!colLookup.has(col.id)) {
                    stateCols.splice(i, 1);
                }
            }
            this.state!.columns = stateCols;
        }
    }
    private hashColumns(columns: GridChartColumn[]) {
        return JSON.stringify(columns);
    }

    private updateDatasource(columns: GridChartColumn[], datasource: QueryDatasource) {
        const nextHash = this.hashDatasource(columns, datasource);
        if (nextHash.datasource !== this.datasourceHash.datasource || nextHash.hash !== this.datasourceHash.hash) {
            this.datasource = this.adaptDatasource(columns, datasource, nextHash);
            this.datasourceHash = nextHash;
            this.viewInvalidated.emit();
        }
        this.loadedDatasource = datasource;
    }
    private adaptDatasource(columns: GridChartColumn[], datasource: QueryDatasource, nextHash: DatasourceHash) {
        const exprs = columns.map((c) => ({ Alias: c.id, Expr: c.select.Expr }));
        const hasAgg = exprs.find(
            (sx) =>
                sx.Expr && traverseExpr(sx.Expr as QueryExpr, (x) => 'Operation' in x && operationLookup.get(x.Operation.toLowerCase())?.aggregate)
        );
        if (hasAgg) {
            this.createAggDatasource(exprs, datasource, nextHash);
            return [];
        } else {
            return this.createBasicDatasource(exprs, datasource);
        }
    }
    private createBasicDatasource(select: QuerySelectExpr[], datasource: QueryDatasource) {
        return {
            getPage: async (start, end, state) => {
                const filters = [...state.filters, ...this.globalFilters];
                const where = filters?.length ? { Operation: 'and', Operands: filters } : undefined;

                const result = await datasource.source({
                    Select: select,
                    IncludeCount: true,
                    Sort: state.sort,
                    Where: where,
                    Take: end - start,
                    Skip: start,
                });
                return {
                    items: (result?.Results ?? []) as Record<string, any>[],
                    total: 0,
                };
            },
        } as DataSourceConfig<Record<string, any>>;
    }
    private async createAggDatasource(select: QuerySelectExpr[], datasource: QueryDatasource, nextHash: DatasourceHash) {
        const filters = [...this.globalFilters];
        const where = filters?.length ? { Operation: 'and', Operands: filters } : undefined;

        const results = await datasource.source({
            Select: select.map((x) => {
                if (x.Expr && 'Field' in x.Expr) {
                    return { Alias: x.Alias, Expr: { Operation: 'values', Operands: [x.Expr, { Value: '' }, { Value: ValuesGroupOtherText }] } };
                } else {
                    return x;
                }
            }),
            IncludeCount: true,
            Where: where,
        });
        const result = (results?.Results ?? []) as Record<string, any>[];
        result.forEach((r) => {
            for (const key in r) {
                if (r[key] === ValuesGroupOtherText) {
                    r[key] = 'Other';
                }
            }
        });

        if (this.datasourceHash.datasource === nextHash.datasource && this.datasourceHash.hash === nextHash.hash) {
            this.datasource = result;
            this.viewInvalidated.emit();
        }
    }
    private hashDatasource(columns: GridChartColumn[], datasource: QueryDatasource) {
        return { datasource, hash: JSON.stringify([columns.map((c) => ({ ...c, select: { Alias: '', Expr: c.select.Expr } })), this.globalFilters]) };
    }
    private raiseViewInvalidated() {
        this.viewInvalidated.emit();
        this.key++;
    }
}

export function GridChart<T extends Record<string, any>>(props: GridChartProps<T>) {
    const fmtSvc = useDi(FormatService);
    const model = useMemo(() => new GridChartModel(fmtSvc), []);
    useEffect(() => {
        model.updateGlobalFilters(props.globalFilters ?? []);
    }, [props.globalFilters]);
    useEffect(() => {
        if (props.settings) {
            model.loadState(props.settings?.state);
        }
    }, []);
    useEvent(props.resized, () => {
        model.dataGrid?.updateRenderSize();
    });
    model.onStateChange = props.onStateChange;
    model.update(props.settings!, props.datasource);
    useEvent(model.viewInvalidated);

    return model.datasource && model.columns ? (
        <DataGrid
            key={model.key}
            dataSource={model.datasource}
            columns={model.columns}
            selectionMode="none"
            state={model.state}
            exportName={props.title ?? 'Export'}
            hideColumnSelector
            onModelLoaded={model.loadGrid}
            onStateChanged={model.saveState}
        />
    ) : null;
}
interface GridChartColumn {
    type: PrimitiveType;
    select: QuerySelectExpr;
    id: string;
    formatter?: (item: unknown) => any;
}
export interface GridChartSettings {
    state: DataGridState;
    columns: GridChartColumn[];
}
export interface GridChartProps<T> extends StandardChartProps<any, T> {
    title?: string;
    settings?: GridChartSettings;
    datasource: QueryDatasource;
    globalFilters?: QueryExpr[];
    resized?: EventEmitter<void>;
    onStateChange: (state: DataGridState) => void;
}
