import type { Company } from '@apis/Customers/model';
import { getSavedSearchGetResources, postResourcesQuery, postResourcesSearch, QueryExpr, QueryField, QueryResult } from '@apis/Resources';
import { BaseAwsResource, BaseResource as ApiBaseResource, IQueryExpr, SavedSearchToken, TagResourcesJob } from '@apis/Resources/model';
import { Anchor, Box, Menu, Text, Button, Popover, Tooltip, Switch, ThemeIcon, Center, Sx } from '@mantine/core';
import { generateColor } from '@root/Design/Primitives';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { PlatformService } from '@root/Services/PlatformService';
import { exprBuilder, FieldInfo, SchemaService, SchemaValueProvider, TypeInfo, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { Node } from '../VirtualTree/Node';
import { Fragment, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { inject, injectable } from 'tsyringe';
import { DataGrid } from '../DataGrid';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { FieldInfoColumnAdapter } from '../DataGrid/FieldInfoColumnAdapter';
import {
    AnonymousValue,
    ChildAccessor,
    ColumnConfig,
    ColumnGroupConfig,
    DataGridStateChange,
    GridColumnState,
    GridGroupByState,
    IDataSource,
    ISelectionStrategy,
} from '../DataGrid/Models';
import { DataColumnConfig, DataGridState } from '../DataGrid/Models';
import { DataFilterPercent, IValueProviderFactory, PercentTokenProvider } from '../Filter/Filters';
import { VisibleSpaces } from '../Text/VisibleSpaces';
import { InlineTagEditService } from './Tags/InlineTagEditService';
import { ResourceDetailsOpener } from './ResourceDetails';
import { TagCell, TagJobListener } from './Tags/TagCell';
import { BasicApi } from '@root/Services/BasicApi';
import { openConfirmModal } from '@mantine/modals';
import { ColumnInsertLeft, FileSpreadsheet, Filter, FilterOff, PlayerSkipForward } from 'tabler-icons-react';
import { useDisclosure, useHover } from '@mantine/hooks';
import { ResourceTagService } from '@root/Site/TagManager/Services/ResourceTagService';
import { postExportExport } from '@apis/Export';
import { ExportRequestTarget, ExportRequestType } from '@apis/Export/model';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { JobService } from '@root/Services/Jobs/JobService';
import { IColumnSelectorRenderOptions } from '../DataGrid/ColumnSelector';
import { IDashboardConfig } from '../DashboardLayout/Models';
import { AuthorizationService } from '@root/Services/AuthorizationService';
import { GridArrayDataSource } from '../DataGrid/DataSources';
import { QueryExprGroupHelper } from '../DataGrid/QueryExprGroupHelper';
import { BaseChartKeySelectionStrategy } from '../DataGrid/BaseChartKeySelectionStrategy';
import { CompanyConfigService } from '@root/Services/Customers/CompanyConfigService';
import { PropertyGridOptionItem, usePropertyGridOptionTranformer } from '../PropertyGrid/PropertyGrid';
import { PropertyGridItem } from '../PropertyGrid/Models';
import { ExprTypeProvider, QueryDescriptorService, SchemaFieldNameProvider } from '../Filter/Services';
import { FilterToken } from '../Filter/Design';
import { ResourcesGridDetailDrawer } from './Grid/ResourceGridDetailsDrawer';

export type BaseResourceId = { CompanyID: number; Id: string; ResourceType: string; CloudPlatform: 'Aws' | 'Azure' };
export type BaseResource = ApiBaseResource & Record<string, any>;
type ResourceGroup = { groupId: string; value: string; count: number; parent?: ResourceGroup };
type ResourceRow = ResourceGroup | BaseResource;

function ResourceGridGroupedBy({ model, groupBy }: { model: ResourceGridModel; groupBy: GridGroupByState }) {
    const column = model.dataGrid?.getColumnById(groupBy.id);
    const sortOptions = useMemo(
        () =>
            [
                { ufMode: 'Count', ufDir: 'Ascending', mode: 'count', dir: 'Asc', icon: 'ti-sort-ascending-numbers' },
                { ufMode: 'Count', ufDir: 'Descending', mode: 'count', dir: 'Desc', icon: 'ti-sort-descending-numbers' },
                { ufMode: 'Alphabetically', ufDir: 'Ascending', mode: 'value', dir: 'Asc', icon: 'ti-sort-ascending' },
                { ufMode: 'Alphabetically', ufDir: 'Descending', mode: 'value', dir: 'Desc', icon: 'ti-sort-descending' },
            ].map((o) => ({
                sort: () => model.dataGrid?.setGroupBySort(groupBy.id, o.mode as 'count' | 'value', o.dir as 'Asc' | 'Desc'),
                text: `Sort by ${o.ufMode} ${o.ufDir}`,
                selected: o.dir === groupBy.sortDir && o.mode === groupBy.sortMode,
                icon: `ti ${o.icon}`,
            })),
        [groupBy.id, groupBy.sortDir, groupBy.sortMode]
    );
    return !column ? (
        <></>
    ) : (
        <Menu withinPortal withArrow position="bottom">
            <Menu.Target>
                <Box>
                    <Anchor>{column?.header}</Anchor>
                </Box>
            </Menu.Target>
            <Menu.Dropdown>
                {sortOptions.map((o, i) => (
                    <Menu.Item
                        key={i}
                        onClick={o.sort}
                        icon={<i className={o.icon}></i>}
                        rightSection={o.selected ? <i style={{ marginLeft: 10 }} className="ti ti-check"></i> : null}
                    >
                        {o.text}
                    </Menu.Item>
                ))}
                <Menu.Divider />
                <Menu.Item onClick={column?.toggleGroupedBy} icon={<i className="ti ti-list"></i>}>
                    Ungroup
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

function ResourceGridStats({ model }: { model: ResourceGridModel }) {
    useEvent(model.dataGrid?.gridStateChanged);
    useEvent(model.dataGrid?.itemCount);
    const formatSvc = useDi(FormatService);
    const itemCount = model.selections.getTotalItems();

    return (
        <>
            <Text color="dimmed" data-atid={'DataGridResults:' + itemCount.toString()}>
                {formatSvc.formatInt(itemCount)} result{itemCount > 1 ? 's' : ''}
            </Text>
        </>
    );
}

@injectable()
export class ResourceGridModel {
    private columnLookup = new Map<string, DataColumnConfig<BaseResource>>();
    private schemaService = new SchemaService([]);
    private hiddenFields = new Set<string>(['CloudPlatform', 'Id', 'ResourceType', 'CompanyID', 'AccountID', 'Account', 'Region']);
    private refreshThrottleMs = 100;
    private refreshThrottled = false;
    private tagJobCompleteActions = new Map<string, (tagResourcesJob: TagResourcesJob) => void>();
    private savedSearchToken?: SavedSearchToken;
    private groupQueryHelper?: QueryExprGroupHelper<ResourceRow>;
    private customColumnIds = new Set<string>();
    public customColumns: ColumnConfig<BaseResource>[] | undefined;
    public allowTagEdit = true;
    public valueProvider?: IValueProviderFactory;
    public dataGrid?: DataGridModel;
    public isLoading = new EventEmitter<boolean>(true);
    public availableColumns: DataColumnConfig<BaseResource>[] = [];
    public state: DataGridState = { columns: [], filters: [], sort: [] };
    public datasource?: IDataSource<BaseResource>;
    public defaultCriteria?: IQueryExpr;
    public groupConfig: { [groupName: string]: ColumnGroupConfig } = {};
    public selections = new QueryBasedResourceSelection();
    public resourceType?: string[];
    public onResourceDetailsSelected = new EventEmitter<BaseResource | undefined>(undefined);
    public columnsLoaded = EventEmitter.empty();
    public previousFilters = '';
    public isInitLoadFinished = new EventEmitter<boolean>(false);
    public showingAllTags = new EventEmitter(false);
    public allowAutoAddTags?: boolean;

    public childAccessor = {
        hasChildren: (item) => {
            return !!item.count;
        },
    } as ChildAccessor<ResourceRow>;

    public get inlineTaggingEnabled() {
        return this.allowTagEdit && this.authSvc.allow('TagManager', 'Tag', this.company.Id);
    }

    private readonly maxGroupByRecords = 1000;

    public exportBlocked = false;
    public export = async (dataGridModel: DataGridModel) => {
        try {
            const config = dataGridModel.getDashboardConfig();
            var combinedFilters = this.defaultCriteria ? [this.defaultCriteria] : [];
            combinedFilters = combinedFilters.concat(...(config.filters ?? []));
            const configCasedRight = {
                Layout: config.layout,
                Name: config.name,
                Filters: combinedFilters,
            };
            configCasedRight.Layout[0].data.DashboardItemName = 'Resources';
            const requestBody = {
                CompanyId: this.company.Id,
                Target: ExportRequestTarget.ResourcesQuery,
                Type: ExportRequestType.Excel,
                DashboardConfig: configCasedRight,
            };
            await postExportExport(requestBody);
            this.notificationSvc.notify(
                'Export Resource Data',
                `Export Requested. Please check the activity log for download.`,
                'primary',
                <ThemeIcon variant="light" size="xl" radius="xl">
                    <FileSpreadsheet />
                </ThemeIcon>
            );
            this.jobService.notifyNewJob();
        } catch {
            this.notificationSvc.notify('Export Failed', `Error: Export failed. Please check the activity log for status details.`, 'error', null);
        }
    };

    public constructor(
        @inject(BasicApi) private readonly basicApi: BasicApi,
        @inject(ResourceSchemaProvider) private resourceSchema: ResourceSchemaProvider,
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(FieldInfoColumnAdapter) private readonly columnAdapter: FieldInfoColumnAdapter<BaseResource>,
        @inject(InlineTagEditService) public readonly inlineTagEditSvc: InlineTagEditService,
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(TagJobListener) public readonly tagJobListener: TagJobListener,
        @inject(PlatformService) public readonly platformSvc: PlatformService,
        @inject(ResourceTagService) public readonly resourceTagSvc: ResourceTagService,
        @inject(NotificationService) private readonly notificationSvc: NotificationService,
        @inject(JobService) private readonly jobService: JobService,
        @inject(AuthorizationService) private readonly authSvc: AuthorizationService,
        @inject(CompanyConfigService) private readonly companyConfigSvc: CompanyConfigService
    ) {}

    public async init(
        resourceType?: string[],
        state?: DataGridState,
        defaultColumns?: GridColumnState[],
        onColumnsLoaded?: (grid: ResourceGridModel) => void,
        customDefaultColumns?: DataColumnConfig<BaseResource>[]
    ) {
        try {
            this.isLoading.emit(true);
            this.tagJobListener.jobsCompleted.listen(this.onTagJobCompleteAction);
            this.tagJobListener.provideArrayDatasource(this.createArrayDatasourceProvider());
            this.resourceType = resourceType;
            this.createBasicDatasource();
            await this.loadColumns(customDefaultColumns);
            this.platformSvc.init();

            onColumnsLoaded?.(this);
            if (state && !state.columns.length && defaultColumns) {
                state.columns = defaultColumns;
            }
            const defaultState = this.createDefaultState(defaultColumns);
            this.state = state ?? defaultState;
            if (!this.state.columns.length) {
                this.state.columns = defaultState.columns;
            }
            this.groupQueryHelper = new QueryExprGroupHelper<ResourceRow>(
                (q) => postResourcesSearch(q, { companyId: this.company.Id }),
                (q) => postResourcesQuery(q, { companyId: this.company.Id }) as unknown as Promise<QueryResult<ResourceRow>>,
                this.maxGroupByRecords,
                this.selections
            );

            const companyConfig = await this.companyConfigSvc.getCompanyConfig();
            if (companyConfig?.some((c) => c.Key === 'Export' && c.Value?.localeCompare('block', undefined, { sensitivity: 'base' }) === 0)) {
                this.exportBlocked = true;
            }
        } finally {
            this.isLoading.emit(false);
        }
    }

    public getSchemaSvc() {
        return this.schemaService;
    }

    public setAndShowTags(show: boolean) {
        this.showingAllTags.emit(show);
        this.showAllTags();
    }

    public toggleShowingAllTags = () => {
        this.setAndShowTags(!this.showingAllTags.value);
    };

    public isColumnIncluded = (column: DataColumnConfig<BaseResource>) => {
        return this.dataGrid?.visibleColumns.some((c) => c.config?.id === column.id);
    };

    public toggleColumn = (column: DataColumnConfig<BaseResource>, include: boolean) => {
        if (include) {
            this.dataGrid?.addColumnById(column.id, 0);
            this.dataGrid?.scrollToColumn(column.id);
        } else {
            this.dataGrid?.removeColumnById(column.id);
        }
    };

    public getFilters = () => this.dataGrid?.gridState?.filters ?? [];

    public addFilter = (filter: QueryExpr) => {
        const filters = this.dataGrid?.gridState?.filters ?? [];
        filters.push(filter);
        this.dataGrid?.applyFilters(filters);
    };

    public showAllTags = async () => {
        if (this.showingAllTags.value && this.dataGrid) {
            const filters = this.dataGrid?.gridState?.filters;
            const query = filters?.length ? { Operation: 'and', Operands: filters } : undefined;
            const tagsForSelectedResources = (await this.resourceTagSvc.getKeysByQuery(query)).filter((t) => !!t).slice(0, 100);
            this.dataGrid?.addColumnsByIds(tagsForSelectedResources.map((tag) => `Tags.CsTags.${tag}`));
            let tagColumns = this.dataGrid?.gridState.columns.filter((c) => c.id.startsWith('Tags.CsTags'));
            let blanks = tagColumns.filter((t) => !tagsForSelectedResources.includes(t.id.replace('Tags.CsTags.', '')));
            this.dataGrid?.removeColumnsById(blanks.map((t) => t.id));
        }
    };

    public pendingShowAll = new EventEmitter(false);
    public handleRenderColumnSelector: IColumnSelectorRenderOptions = (() => {
        const pendingShowAll = this.pendingShowAll;
        function ColumnGroupRenderer({ children, groupName }: { children: ReactNode; groupName?: string }) {
            const showAll = useEventValue(pendingShowAll);
            return <>{!showAll || groupName !== 'Tags' ? children : null}</>;
        }
        return {
            renderColumnGroup: (groupNode, groupName) => {
                if (!this.allowAutoAddTags) {
                    return groupNode;
                }
                return <ColumnGroupRenderer groupName={groupName}>{groupNode}</ColumnGroupRenderer>;
            },
            renderItemList: (itemNodes) => {
                if (!this.allowAutoAddTags) {
                    return itemNodes;
                }
                const showAll = useEventValue(pendingShowAll);
                return (
                    <>
                        {itemNodes}
                        {!showAll ? null : (
                            <>
                                <Text size="xs" color="gray">
                                    Tags
                                </Text>
                                <Text align="center" size="sm" italic>
                                    Tag columns managed automatically
                                </Text>
                            </>
                        )}
                    </>
                );
            },
            renderFooter: (footer, model) => {
                if (!this.allowAutoAddTags) {
                    return footer;
                }
                const onShowAllChange = useCallback(
                    (checked: boolean) => {
                        pendingShowAll.emit(checked);
                        model.externallyChanged.emit();
                    },
                    [model]
                );
                useEffect(() => {
                    pendingShowAll.emit(this.showingAllTags.value);
                    return model.onApplying.listen(() => {
                        if (this.showingAllTags.value !== pendingShowAll.value) {
                            this.setAndShowTags(pendingShowAll.value);
                        }
                    }).dispose;
                }, [model]);
                return (
                    <Box>
                        <Center p="md">
                            <Tooltip
                                label={
                                    <>
                                        Allow tag columns to be automatically added and
                                        <br /> removed so that only applicable tag columns appear.
                                    </>
                                }
                            >
                                <Switch
                                    onChange={(e) => onShowAllChange(e.currentTarget.checked)}
                                    checked={pendingShowAll.value}
                                    label="Auto-Add Tag Columns"
                                    data-atid="AutoAddTagColumnsToggle"
                                />
                            </Tooltip>
                        </Center>
                        {footer}
                    </Box>
                );
            },
        };
    })();

    public schemaFilter = (item: FieldInfo | TypeInfo) => {
        return this.pendingShowAll.value ? 'rootType' in item && item.rootType.name !== 'Tags' : true;
    };

    public addTagCompleteJobAction(jobId: string, callback: (tagResourcesJob: TagResourcesJob) => void) {
        this.tagJobCompleteActions.set(jobId, callback);
    }
    private onTagJobCompleteAction = (tagResourcesJob: TagResourcesJob[]) => {
        for (const job of tagResourcesJob) {
            if (job.JobId && this.tagJobCompleteActions.has(job.JobId)) {
                let actionCallback = this.tagJobCompleteActions.get(job.JobId);
                if (actionCallback) {
                    actionCallback(job);
                }
                this.tagJobCompleteActions.delete(job.JobId);
            }
        }
    };

    public dispose() {
        this.tagJobListener.dispose();
    }

    public refreshDataOnly() {
        if (!this.refreshThrottled) {
            this.refreshThrottled = true;
            setTimeout(() => (this.refreshThrottled = false), this.refreshThrottleMs);
            this.dataGrid?.refresh(false);
        }
    }

    public handleRefresh = async () => {
        if (this.dataGrid) {
            await this.loadColumns();
            this.dataGrid.loadSchema(this.schemaService);
            this.dataGrid.loadAvailableColumns(this.availableColumns);
        }
    };

    public attachGrid = (grid: DataGridModel) => {
        this.dataGrid = grid;
        this.dataGrid.loadSchema(this.schemaService);
        this.dataGrid.gridStateChanged.listen(this.handleStateChange);
        this.groupQueryHelper?.attach(grid);
        this.updateSelectionCount();
    };

    public handleStateSaving = (state: IDashboardConfig) => {
        if (state.layout[0]?.data) {
            state.layout[0].data.ShowingAllTags = this.allowAutoAddTags && this.showingAllTags.value;
        }
    };

    public handleStateLoaded = (state: IDashboardConfig | undefined) => {
        if (!state) {
            if (this.allowAutoAddTags) {
                this.setAndShowTags(true);
            }
        } else {
            let showingAll = state.layout[0]?.data?.ShowingAllTags;
            if (showingAll === undefined) {
                showingAll = this.allowAutoAddTags;
            }
            if (showingAll !== this.showingAllTags.value) {
                this.setAndShowTags(showingAll);
            }
        }
    };

    public async loadColumns(customColumns?: DataColumnConfig<BaseResource>[]) {
        let types = await this.resourceSchema.getSchema();
        this.availableColumns = [];
        this.groupConfig = {};
        this.columnLookup.clear();

        if (this.resourceType?.length) {
            types = types.filter((type) => {
                return (
                    !type.IsRoot ||
                    type.Name == 'Common' ||
                    type.Name == 'Tags' ||
                    this.resourceType?.includes(type.Name ?? '') ||
                    type.TypeId?.startsWith('integration-metadata-')
                );
            });
        }

        if (customColumns?.length) {
            types = [...types];
            this.customColumnIds.clear();
            this.customColumns = [];
            for (const column of customColumns) {
                const rootType = types.find((t) => t.Name === column.groupName && t.IsRoot);
                if (rootType) {
                    this.addColumn(column);
                    this.customColumnIds.add(column.id);
                    this.customColumns.push(column);
                    rootType.Fields?.push({
                        Description: column.helpText,
                        Field: column.id,
                        Name: column.header,
                        TypeName: column.type,
                        IsPrimitive: true,
                        HasMany: false,
                    });
                }
            }
        }

        this.schemaService = new SchemaService(types);
        const schemaValueProvider = new SchemaValueProvider(this.schemaService, (query) => postResourcesQuery(query, { companyId: this.company.Id }));

        this.valueProvider = {
            getValueProvider: (field: QueryField) => {
                if (field.Field === 'CloudPlatform') {
                    return this.platformSvc.getPickerOptions();
                } else {
                    return schemaValueProvider.getValueProvider(field);
                }
            },
        };
        this.schemaService.resolveChildren();
        this.addBaseColumns();

        for (const type of this.schemaService.rootTypeInfo) {
            this.addSchemaColumns(type, type);
        }
        this.columnsLoaded.emit();
    }

    public addConfigColumnsOrderedByType(columnConfigs: DataColumnConfig<BaseResource>[]) {
        if (columnConfigs && columnConfigs.length > 0 && this.dataGrid) {
            let nonVisibleColumns = columnConfigs.filter((c) => !this.dataGrid!.gridState.columns.map((vc) => vc.id).includes(c.id));
            if (nonVisibleColumns && nonVisibleColumns.length > 0) {
                let columns = this.dataGrid.gridState.columns.map((c) => this.columnLookup.get(c.id));
                nonVisibleColumns.forEach((config) => {
                    let index = this.findFirstIndexOfType(config.id, this.state.columns.length);
                    columns.splice(index, 0, config);
                });
                this.dataGrid.applyColumnSelection(columns as DataColumnConfig<BaseResource>[]);
            }
        }
    }

    public addGridColumn(column: DataColumnConfig<BaseResource>) {
        if (!this.columnLookup.has(column.id)) {
            this.addColumn(column);
        }
    }

    public displayGridColumns(newTags: string[], jobId: string) {
        let columns = this.availableColumns.filter((c) => newTags.includes(c.id));
        let unknownColumns = newTags.filter((t) => !columns.map((c) => c.id).includes(t));
        // If the column(s) are unknown, we are adding a job listener to update after tag job completes.
        if (unknownColumns.length > 0 && jobId) {
            this.addTagCompleteJobAction(jobId, async (jobs: TagResourcesJob) => {
                await this.handleRefresh();
                let newColumns = this.availableColumns.filter((c) => unknownColumns.includes(c.id));
                this.addConfigColumnsOrderedByType(newColumns);
            });
        }
        this.addConfigColumnsOrderedByType(columns);
    }

    public gridRowIdProvider = (item: BaseResource) => {
        return `${item.Id}/${item.ResourceType}`;
    };

    public renderStats = () => {
        return <ResourceGridStats model={this} />;
    };

    public renderGroupBy = (row: Node<ResourceRow>) => {
        return (
            <>
                {row.item.value === ValuesGroupOtherText ? 'Other' : row.item.value} ({this.formatSvc.formatInt(row.item.count)})
            </>
        );
    };

    public pauseFilter(savedSearchToken?: SavedSearchToken) {
        this.savedSearchToken = savedSearchToken;
        this.dataGrid?.filterDisabled.emit(true);
    }

    public resumeFilter = () => {
        this.clearingFilter();
        this.dataGrid?.refresh(false);
    };

    public clearingFilter = () => {
        this.savedSearchToken = undefined;
        this.dataGrid?.filterDisabled.emit(false);
    };

    public isFilterPaused() {
        return !!this.savedSearchToken;
    }

    private findFirstIndexOfType(id: string, defaultIndex: number) {
        let sections = id.split('.');
        let index = defaultIndex;
        if (sections && sections.length > 1 && this.dataGrid?.gridState) {
            index = this.dataGrid.gridState.columns.findIndex((item) => item.id.startsWith(`${sections[0]}.`));
            index = index === -1 ? defaultIndex : index;
            index = index < 2 ? (index = 2) : index;
        }
        return index;
    }

    private addSchemaColumns(type: TypeInfo, rootType: TypeInfo) {
        if (type.fields) {
            for (const field of type.fields) {
                if ((type.parent && this.hiddenFields.has(field.fieldName!)) || this.customColumnIds.has(field?.field.Field ?? '')) {
                    continue;
                }
                if (
                    !this.resourceType?.length ||
                    this.resourceType.includes(rootType.name) ||
                    rootType.type.TypeId?.startsWith('integration-metadata-') ||
                    rootType.name === 'Tags'
                ) {
                    if (!field.isPrimitive && field.children && field.typeInfo) {
                        this.addSchemaColumns(field.typeInfo, rootType);
                    } else {
                        const column = this.createBasicColumn(field, type, rootType);
                        if (column) {
                            this.addColumn(column);
                        }
                    }
                }
            }
        }
    }
    private addBaseColumns() {
        const columns: DataColumnConfig<BaseResource | BaseAwsResource>[] = [
            {
                accessor: (item) => item.Id,
                id: 'Base.Name',
                sortField: 'Name',
                header: 'ID',
                defaultWidth: 230,
                cellRenderer: (resource) => (
                    <Anchor
                        onClick={() => {
                            this.onResourceDetailsSelected.emit(resource);
                        }}
                        data-atid={'ResourceNameLink:' + resource.Name}
                    >
                        {resource.Name}
                    </Anchor>
                ),
                defaultFixed: true,
                type: 'string',
                filter: {
                    filterType: 'string',
                    name: 'ID',
                    filterField: 'Name',
                },
            },
            {
                accessor: (item) => item.ResourceType,
                id: 'Base.ResourceType',
                sortField: 'ResourceType',
                defaultWidth: 100,
                header: 'Resource Type',
                defaultFixed: true,
                type: 'string',
                allowGrouping: true,
                filter: {
                    filterType: 'string',
                    name: 'Resource Type',
                    filterField: 'ResourceType',
                },
            },
            {
                accessor: (item) => item.Last30DaysCost,
                id: 'Base.Last30DaysCost',
                sortField: 'Last30DaysCost',
                defaultWidth: 100,
                header: 'Last 30 Day Cost',
                type: 'number',
                align: 'right',
                cellRenderer: (item) => {
                    var cost = item.Last30DaysCost ?? 0;
                    if (cost > 0 && cost < 0.01) return '< $0.01';
                    else return this.formatSvc.formatMoney(item.Last30DaysCost ?? 0);
                },
                filter: {
                    filterType: 'number',
                    name: 'Last 30 Days Cost',
                    filterField: 'Last30DaysCost',
                },
            },
            {
                accessor: (item) => item.AnnualizedCost,
                id: 'Base.AnnualizedCost',
                sortField: 'AnnualizedCost',
                defaultWidth: 100,
                header: 'Annualized Cost',
                helpText: 'Previous 7 Days cost extrapolated for 365 days.',
                type: 'number',
                align: 'right',
                cellRenderer: (item) => {
                    var cost = item.AnnualizedCost ?? 0;
                    if (cost > 0 && cost < 0.01) return '< $0.01';
                    else return typeof item.AnnualizedCost === 'number' ? this.formatSvc.formatMoney(item.AnnualizedCost ?? 0) : 'Not Available';
                },
                filter: {
                    filterType: 'number',
                    name: 'Annualized Cost',
                    filterField: 'AnnualizedCost',
                },
            },
            {
                accessor: (item) => item.ExtendedAnnualizedCost,
                id: 'Base.ExtendedAnnualizedCost',
                sortField: 'ExtendedAnnualizedCost',
                defaultWidth: 100,
                header: 'Extended Annualized Cost',
                helpText: 'Annualized Cost calculated for life of resource (up to 365 days).',
                type: 'number',
                align: 'right',
                cellRenderer: (item) => {
                    var cost = item.ExtendedAnnualizedCost ?? 0;
                    if (cost > 0 && cost < 0.01) return '< $0.01';
                    else
                        return typeof item.ExtendedAnnualizedCost === 'number'
                            ? this.formatSvc.formatMoney(item.ExtendedAnnualizedCost ?? 0)
                            : 'Not Available';
                },
                filter: {
                    filterType: 'number',
                    name: 'Extended Annualized Cost',
                    filterField: 'ExtendedAnnualizedCost',
                },
            },
            {
                accessor: (item) => item.Region,
                id: 'Base.Region',
                sortField: 'Region',
                defaultWidth: 100,
                header: 'Region',
                type: 'string',
                allowGrouping: true,
                filter: {
                    filterType: 'string',
                    name: 'Region',
                    filterField: 'Region',
                },
            },
            {
                accessor: (item) => (item.CloudPlatform == 'Aws' ? 'AWS' : item.CloudPlatform),
                id: 'Base.CloudPlatform',
                sortField: 'CloudPlatform',
                defaultWidth: 100,
                header: 'Cloud Platform',
                type: 'string',
                allowGrouping: true,
                filter: {
                    filterType: 'string',
                    name: 'CloudPlatform',
                    filterField: 'CloudPlatform',
                    options: { getValueProvider: () => [{ label: 'AWS', value: 'Aws' }] },
                },
            },
            {
                accessor: (item) => item.Account,
                id: 'Base.Account',
                sortField: 'Account',
                defaultWidth: 80,
                header: 'Account ID',
                type: 'string',
                allowGrouping: true,
                filter: {
                    filterType: 'string',
                    name: 'Account ID',
                    filterField: 'Account',
                },
            },
            {
                accessor: (item) => item.AccountName,
                id: 'Base.AccountName',
                sortField: 'AccountName',
                defaultWidth: 200,
                header: 'Account Name',
                type: 'string',
                allowGrouping: true,
                filter: {
                    filterType: 'string',
                    name: 'Account Name',
                    filterField: 'AccountName',
                },
            },
            {
                accessor: 'Base.Tags.Key',
                id: 'Base.Tags.Key',
                sortField: 'Base.Tags.Key',
                defaultWidth: 200,
                header: 'Tag Keys',
                type: 'string',
                noRemove: true,
                defaultHidden: true,
                filter: {
                    filterType: 'string',
                    name: 'Tag Keys',
                    filterField: 'Tags.Key',
                },
            },
            {
                accessor: 'CreateDate',
                id: 'Base.CreateDate',
                sortField: 'CreateDate',
                defaultWidth: 200,
                header: 'Create Date',
                type: 'date',
                filter: {
                    filterType: 'date',
                    name: 'Create Date',
                    filterField: 'CreateDate',
                },
                formatter: (_: BaseResource, value: Date) => {
                    const date = typeof value === 'string' ? this.formatSvc.toLocalDate(value) : value;
                    return value === null ? 'Unknown' : this.formatSvc.formatDate(date);
                },
            },
            {
                accessor: 'LastSyncDate',
                id: 'Base.LastSyncDate',
                sortField: 'LastSyncDate',
                defaultWidth: 200,
                header: 'Last Sync Date',
                type: 'date',
                filter: {
                    filterType: 'date',
                    name: 'Last Sync Date',
                    filterField: 'LastSyncDate',
                },
                formatter: (_: BaseResource, value: Date) => {
                    const date = typeof value === 'string' ? this.formatSvc.toLocalDate(value) : value;
                    return value === null ? 'Unknown' : this.formatSvc.formatDatetimeNoSecs(date);
                },
            },
            {
                accessor: 'Base.Tags.Value',
                id: 'Base.Tags.Value',
                sortField: 'Tags.Value',
                defaultWidth: 200,
                header: 'Tag Values',
                type: 'string',
                noRemove: true,
                defaultHidden: true,
                filter: {
                    filterType: 'string',
                    name: 'Tag Values',
                    filterField: 'Tags.Value',
                },
            },
            {
                accessor: 'Base.TagKeyValuePairs',
                id: 'Base.TagKeyValuePairs',
                sortField: 'TagKeyValuePairs',
                defaultWidth: 200,
                header: 'Tag Key:Value Pairs',
                type: 'string',
                noRemove: true,
                defaultHidden: true,
                filter: {
                    filterType: 'string',
                    name: 'Tag Key: Value Pairs',
                    filterField: 'Base.TagKey:ValuePairs',
                },
            },
            {
                accessor: 'Base.ClarityScore',
                id: 'Base.ClarityScore',
                sortField: 'ClarityScore',
                defaultWidth: 100,
                header: 'Clarity Score',
                type: 'number',
                cellRenderer: (item) => (typeof item.ClarityScore !== 'number' ? '' : this.formatSvc.formatPercent(item.ClarityScore)),
                filter: {
                    filterType: 'number',
                    name: 'Clarity Score',
                    filterField: 'ClarityScore',
                    valueRenderer: DataFilterPercent,
                    tokenProvider: PercentTokenProvider,
                },
            },
            {
                accessor: 'Base.ComplianceScore',
                id: 'Base.ComplianceScore',
                sortField: 'ComplianceScore',
                defaultWidth: 100,
                header: 'Compliance Score',
                type: 'number',
                cellRenderer: (item) => (typeof item.ComplianceScore !== 'number' ? '' : this.formatSvc.formatPercent(item.ComplianceScore)),
                filter: {
                    filterType: 'number',
                    name: 'Compliance Score',
                    filterField: 'ComplianceScore',
                    valueRenderer: DataFilterPercent,
                    tokenProvider: PercentTokenProvider,
                },
            },
            {
                accessor: 'Base.CoverageScore',
                id: 'Base.CoverageScore',
                sortField: 'CoverageScore',
                defaultWidth: 100,
                header: 'Coverage Score',
                type: 'number',
                cellRenderer: (item) => (typeof item.CoverageScore !== 'number' ? '' : this.formatSvc.formatPercent(item.CoverageScore)),
                filter: {
                    filterType: 'number',
                    name: 'Coverage Score',
                    filterField: 'CoverageScore',
                    valueRenderer: DataFilterPercent,
                    tokenProvider: PercentTokenProvider,
                },
            },
            {
                accessor: 'Base.TagHealthScore',
                id: 'Base.TagHealthScore',
                sortField: 'TagHealthScore',
                defaultWidth: 100,
                header: 'Tag Health Score',
                type: 'number',
                cellRenderer: (item) => (typeof item.TagHealthScore !== 'number' ? '' : this.formatSvc.formatPercent(item.TagHealthScore)),
                filter: {
                    filterType: 'number',
                    name: 'Tag Health Score',
                    filterField: 'TagHealthScore',
                    valueRenderer: DataFilterPercent,
                    tokenProvider: PercentTokenProvider,
                },
            },
            {
                accessor: 'Base.BillingKey',
                id: 'Base.BillingKey',
                sortField: 'BillingKey',
                helpText: "Billing key is an identifier used to match resources to their line items in the cloud provider's invoice.",
                defaultWidth: 100,
                header: 'Billing Key',
                type: 'string',
                filter: {
                    filterType: 'string',
                    name: 'Billing Key',
                    filterField: 'BillingKey',
                },
            },
        ];
        for (const column of columns) {
            this.addColumn(column);
            this.addToLookup(column, 'base', '', column.sortField ?? column.id);
        }
        this.groupConfig['Tags'] = { color: '#B0E8FF' };
    }

    private createArrayDatasourceProvider() {
        let datasource: GridArrayDataSource | undefined;
        return (resource: BaseResource) => {
            if (!this.dataGrid) {
                throw new Error('Attempted to create array datasource before dataGrid was available');
            }
            if (!datasource) {
                datasource = this.dataGrid?.createArrayDataSource([]);
            }
            datasource.items = [resource];
            return datasource;
        };
    }

    private addColumn(column: DataColumnConfig<BaseResource>) {
        this.availableColumns.push(column);
        this.columnLookup.set(column.id, column);
    }

    private addToLookup(column: DataColumnConfig<BaseResource>, columnType: 'base' | 'intg' | 'tags' | 'type', typeKey = '', fieldPath: string) {
        const key = `${columnType}/${typeKey}/${fieldPath}`;
        if (!this.columnLookup.has(key)) {
            this.columnLookup.set(key, column);
        }
    }

    public getColumnById(platform: string, resourceType: string, fieldPath: string) {
        const possibleIds = [`base//${fieldPath}`, `tags//${fieldPath}`, `intg//${fieldPath}`, `type/${platform}-${resourceType}/${fieldPath}`];
        for (const id of possibleIds) {
            const column = this.columnLookup.get(id);
            if (column) {
                return { columnConfig: column, columnModel: this.dataGrid?.getColumnById(column.id) };
            }
        }
    }

    private createBasicColumn(field: FieldInfo, type: TypeInfo, rootType: TypeInfo) {
        const column = this.columnAdapter.adapt(field);
        column.defaultHidden = true;
        if (column.type === 'string') {
            column.allowGrouping = true;
        }
        if (rootType.type.TypeId === 'Base') {
            return this.columnLookup.has(field.pathWithRoot) ? null : column;
        } else if (rootType.type.TypeId === 'Tags') {
            return this.createTagColumn(field, column);
        } else if (rootType.type.TypeId?.startsWith('integration-')) {
            return this.createIntegrationColumn(field, column);
        } else {
            return this.createResourceTypeColumn(field, column);
        }
    }

    private createTagColumn(fieldInfo: FieldInfo, column: DataColumnConfig<BaseResource>) {
        column.accessor = (item: BaseResource) => item.CsTags?.[fieldInfo.name];
        column.cellRenderer = (item: BaseResource) => <TagCell grid={this} item={item} tag={fieldInfo.name} />;
        column.groupName = 'Tags';
        column.headerRenderer = () => <VisibleSpaces value={fieldInfo.name} />;
        this.addToLookup(column, 'tags', '', fieldInfo.path);
        return column;
    }

    private createIntegrationColumn(fieldInfo: FieldInfo, column: DataColumnConfig<BaseResource>) {
        const [integrationName, ...path] = fieldInfo.path.split('.');
        const fieldPath = path.join('.');
        const accessor = (item: BaseResource) => item[integrationName]?.[fieldPath];
        column.accessor = accessor;
        column.cellRenderer = (item: BaseResource) => <>{accessor(item)}</>;
        column.groupName = integrationName;
        if (!this.groupConfig[integrationName]) {
            this.groupConfig[integrationName] = { color: generateColor(integrationName) };
        }
        this.addToLookup(column, 'intg', '', `${integrationName}.${fieldPath}`);
        return column;
    }

    private createResourceTypeColumn(fieldInfo: FieldInfo, column: DataColumnConfig<BaseResource>) {
        let actualAccessor = column.accessor as (item: BaseResource) => AnonymousValue;
        if (this.resourceType?.length !== 1) {
            column.groupName = fieldInfo.rootType.name;
            this.groupConfig[column.groupName] = { color: generateColor(fieldInfo.rootType.name) };
        }
        if (this.resourceSchema.isAwsEnum(fieldInfo)) {
            const path = this.resourceSchema.resolveAccessPath(fieldInfo);
            const accessorPath = path.map((accessor, i) => ({ accessor, isLeaf: i + 1 === path.length }));
            actualAccessor = this.columnAdapter.createAccessor(accessorPath);
        }

        column.accessor = (item: BaseResource) => {
            return item.ResourceType === fieldInfo.rootType.name || item.CloudPlatform === 'Azure'
                ? actualAccessor(item)
                : ({ first: undefined, hasMany: false, values: [] } as AnonymousValue);
        };
        this.adjustColumn(column);
        const [platform, type] = this.getTypeInfoTypePlatform(fieldInfo);
        this.addToLookup(column, 'type', `${platform}-${type}`, fieldInfo.path);
        return column;
    }

    private getTypeInfoTypePlatform(fieldInfo: FieldInfo) {
        if (fieldInfo.owner?.type.TypeId?.startsWith('azure')) {
            const [platform, ...type] = fieldInfo.owner?.type.TypeId?.split('/') ?? [];
            return [platform, type.join('/').replace(/_/g, '.').toLowerCase()];
        } else {
            const [, platform, , type] = (fieldInfo.rootType.type.TypeId ?? '').split('-');
            return [platform, type];
        }
    }

    private adjustColumn(column: DataColumnConfig<BaseResource>) {
        if (column.id.includes('ec2_on_demand') && column.id.endsWith('Pricing.PricePerUnit')) {
            column.formatter = (_, value) => (typeof value === 'number' ? this.formatSvc.formatMoney4Decimals(value) + ' / hr' : '');
        }
    }

    private createDefaultState(defaultColumns?: GridColumnState[]) {
        var columns: { id: string; width: number }[] = [];
        const { resourceType } = this;

        //var showExtraBaseColumns = this.schemaService.getRoots().length > 0;

        const defaultCols =
            defaultColumns ??
            ['Base.Name', 'Base.Account', 'Base.ResourceType', 'Base.Region'].map((id) => ({
                id,
                width: this.columnLookup.get(id)?.defaultWidth ?? 100,
            }));
        columns = defaultCols;

        return {
            columns: columns,
            filters: [],
            sort: [],
            resourceType: resourceType,
        } as DataGridState;
    }

    private handleStateChange = (change: DataGridStateChange | undefined) => {
        if (change?.changes.has('filters') || change?.changes.has('groupBy')) {
            this.updateSelectionCount();
        }
        if (change?.changes.has('filters')) {
            this.showAllTags();
        }
    };

    private async updateSelectionCount() {
        const state = this.dataGrid?.gridState;
        if (state && state.groupBy?.length) {
            const { where } = await this.getQuery(state);
            const response = await postResourcesSearch({ Where: where, Take: 0 }, { companyId: this.company.Id });
            this.selections.updateQuery(where, response.Count ?? 0);
        }
    }

    public createBasicDatasource() {
        const grid = this;
        this.datasource = {
            async getPage(start: number, end: number, state: DataGridState, parent?: ResourceRow) {
                return grid.savedSearchToken?.Id
                    ? await grid.getSavedSearchPage(start, end, grid.savedSearchToken?.Id)
                    : parent && !parent.groupId
                    ? await grid.getChildPage(start, end, state, parent)
                    : await grid.getPage(start, end, state, parent);
            },
        } as IDataSource<ResourceRow>;
    }

    private async getSavedSearchPage(start: number, end: number, savedSearchId: string) {
        const results = await getSavedSearchGetResources({ savedSearchId, skip: start, take: end - start + 1 });
        return { items: results.Results ?? [], total: results.Count ?? 0 };
    }

    private async getPage(start: number, end: number, state: DataGridState, parent?: ResourceRow) {
        return await this.groupQueryHelper!.getPage(start, end, state, parent, this.defaultCriteria as QueryExpr);
    }

    private async getChildPage(start: number, end: number, state: DataGridState, parent?: ResourceRow) {
        const parentResource = parent! as BaseResource;
        const relationshipCriteria = exprBuilder<BaseResource>().createExpr((b) =>
            b.and(b.model['ParentResourceId.Id']!.eq(parentResource.Id), b.model['ParentResourceId.ResourceType']!.eq(parentResource.ResourceType))
        );

        return await this.groupQueryHelper!.getPage(start, end, state, undefined, relationshipCriteria as QueryExpr, true);
    }

    public getQuery(state?: DataGridState, parent?: ResourceRow) {
        return this.groupQueryHelper!.getQuery(state, this.defaultCriteria as QueryExpr);
    }
}

export interface IResourceGridSelection {
    getResourceQuery(): IQueryExpr | undefined;
    allSelected(): boolean;
    getIncluded(): BaseResourceId[];
    getExcluded(): BaseResourceId[];
    queryChanged: EventEmitter<void>;
    count(): number;
}

export class QueryBasedResourceSelection implements ISelectionStrategy<BaseResourceId>, IResourceGridSelection {
    private isSelectAll = false;
    private deselected = new Set<string>();
    private selected = new Set<string>();
    private query?: QueryExpr;
    public itemCount = new EventEmitter(0);

    public queryChanged = EventEmitter.empty();

    public getResourceQuery() {
        return this.query;
    }

    public allSelected() {
        return this.isSelectAll;
    }

    public getTotalItems() {
        return this.itemCount.value;
    }

    public updateQuery(query: QueryExpr | undefined, count: number) {
        this.query = query;
        this.itemCount.emit(count);
        this.queryChanged.emit();
    }

    public createIdCriteria() {
        const manualSelections = [...(this.isSelectAll ? this.deselected : this.selected)];
        let result: QueryExpr | undefined;
        if (manualSelections.length) {
            const ids: BaseResourceId[] = [];
            manualSelections.forEach((r) => ids.push(this.deserializeId(r)));
            const idCriteria = ids.map((id) =>
                exprBuilder<BaseResource>().createExpr((b) =>
                    b.and(b.model.CloudPlatform!.eq(id.CloudPlatform!), b.model.Id!.eq(id.Id!), b.model.ResourceType!.eq(id.ResourceType))
                )
            );
            result = {
                Operation: 'or',
                Operands: idCriteria,
            };
        }
        if (this.isSelectAll && result) {
            result = { Operation: 'not', Operands: [result] };
        }
        return result;
    }

    public isSelected(item: BaseResourceId): boolean {
        const id = this.getId(item);
        return (this.isSelectAll && !this.deselected.has(id)) || (!this.isSelectAll && this.selected.has(id));
    }
    public getSelectAllValidity(): string | undefined {
        return undefined;
    }
    public setSelected(item: BaseResourceId, selected: boolean) {
        const id = this.getId(item);
        if (this.isSelectAll) {
            if (selected) {
                this.deselected.delete(id);
            } else {
                this.deselected.add(id);
            }
        } else {
            if (selected) {
                this.selected.add(id);
            } else {
                this.selected.delete(id);
            }
        }
    }
    public async setSelectAll(selected: boolean): Promise<void> {
        this.isSelectAll = selected;
        this.selected.clear();
        this.deselected.clear();
    }
    public async getSelected(): Promise<BaseResourceId[]> {
        return this.getIncluded();
    }
    public getIncluded(): BaseResourceId[] {
        return [...this.selected].map((r) => this.deserializeId(r));
    }
    public getExcluded() {
        return [...this.deselected].map((r) => this.deserializeId(r));
    }
    public count() {
        return this.isSelectAll ? this.itemCount.value - this.deselected.size : this.selected.size;
    }
    private getId(item: BaseResource) {
        return JSON.stringify([item.CompanyID, item.ResourceType, item.Id, item.CloudPlatform]);
    }
    private deserializeId(id: string) {
        const [CompanyID, ResourceType, Id, CloudPlatform] = JSON.parse(id);
        return { CompanyID, ResourceType, Id, CloudPlatform };
    }
    public clearSelected() {
        this.setSelectAll(false);
    }
}

interface ResourceGridProps {
    title?: string;
    persistenceKey?: string;
    resourceType?: string | string[];
    onModelLoaded?: (model: ResourceGridModel) => void;
    defaultCriteria?: IQueryExpr;
    onColumnsLoaded?: (model: ResourceGridModel) => void;
    defaultColumns?: GridColumnState[];
    defaultState?: DataGridState;
    showRefresh?: boolean;
    noSelect?: boolean;
    allowAutoAddTags?: boolean;
    leftTopPlaceHolder?: ReactNode;
    leftFilterPlaceHolder?: ReactNode;
    leftResultsPlaceHolder?: ReactNode;
    rightTopPlaceHolder?: ReactNode;
    allowSavedViews?: boolean;
    childAccessor?: ChildAccessor<BaseResource>;
    customDefaultColumns?: DataColumnConfig<BaseResource>[];
    renderRowSelector?: (
        item: BaseResource | null,
        selectionState: { selected?: boolean; some?: boolean; all?: boolean; toggle: (selected: boolean) => void },
        isHeader: boolean
    ) => ReactNode;
}
export function ResourcesGrid({ onModelLoaded, ...props }: ResourceGridProps) {
    const di = useDiContainer();
    const model = useMemo(() => {
        const result = di.resolve(ResourceGridModel);
        result.allowAutoAddTags = props.allowAutoAddTags;
        result.defaultCriteria = props.defaultCriteria;
        result.init(
            typeof props.resourceType === 'string' ? [props.resourceType] : props.resourceType,
            props.defaultState,
            props.defaultColumns,
            props.onColumnsLoaded,
            props.customDefaultColumns
        );
        return result;
    }, []);
    useEffect(() => {
        model.defaultCriteria = props.defaultCriteria;
    }, [model, props.defaultCriteria]);
    useEvent(model.isLoading);
    useEvent(model.dataGrid?.groupByChanged);
    useEffect(() => {
        return () => model.dispose();
    }, []);

    const handleModelLoaded = (dataGrid: DataGridModel) => {
        model.attachGrid(dataGrid);
        onModelLoaded?.(model);
    };

    const handleAddFilterAddColumn = (filters: IQueryExpr[]) => {
        if (filters[0] && 'Operands' in filters[0] && filters[0].Operands?.[0] && 'Field' in filters[0].Operands[0]) {
            const field: string = filters[0].Operands[0]['Field'];
            if (field) {
                let columnConfigs = model.availableColumns.filter((ac) => {
                    let filterField = typeof ac.filter === 'object' ? ac.filter.filterField : '';
                    return filterField === field;
                });

                if (columnConfigs && columnConfigs.length > 0) {
                    model.addConfigColumnsOrderedByType(columnConfigs as DataColumnConfig<BaseResource>[]);
                }
            }
        }
    };

    const confirmResumeFilter = () => {
        let resolve = () => {};
        const result = new Promise<void>((r) => (resolve = r));
        if (model.isFilterPaused()) {
            openConfirmModal({
                onConfirm: () => {
                    model.resumeFilter();
                    resolve();
                },
                title: <Text weight="bold">Filtering Paused</Text>,
                children: <Text>Filtering paused to allow monitoring changes</Text>,
                labels: {
                    confirm: 'Resume Filtering',
                    cancel: 'Cancel',
                },
            });
        } else {
            resolve();
        }
        return result;
    };

    return model.isLoading.value ? (
        <></>
    ) : (
        <>
            <DataGrid
                allowPinning
                filterValueProvider={model.valueProvider}
                dataSource={model.datasource!}
                columns={model.availableColumns}
                showRefresh={props.showRefresh}
                onRefreshing={model.handleRefresh}
                childAccessor={props.childAccessor !== undefined ? props.childAccessor : model.childAccessor}
                statePersistence={props.persistenceKey ? { key: props.persistenceKey } : undefined}
                state={model.state}
                selectionMode={props.noSelect ? 'none' : 'multiple'}
                groupConfig={model.groupConfig}
                defaultGroupName="All Resources"
                selectionStrategy={model.selections}
                onModelLoaded={handleModelLoaded}
                showHeaderGroups={true}
                showNumSelected={!props.noSelect}
                showCount={false}
                schemaFilter={model.schemaFilter}
                itemCount={model.selections.itemCount}
                renderStats={model.renderStats}
                allowGroupBy
                customColumns={model.customColumns}
                renderGroupBy={model.renderGroupBy}
                onStateSaving={model.handleStateSaving}
                onStateLoaded={model.handleStateLoaded}
                allowSavedViews={props.allowSavedViews}
                onFilterClearing={model.clearingFilter}
                onStateReverting={model.clearingFilter}
                onFilterAdding={confirmResumeFilter}
                onBeforeGroupBy={confirmResumeFilter}
                onBeforeSort={confirmResumeFilter}
                onFilterAdded={handleAddFilterAddColumn}
                leftFilterPlaceHolder={props.leftFilterPlaceHolder}
                leftTopPlaceHolder={props.leftTopPlaceHolder}
                rightTopPlaceHolder={props.rightTopPlaceHolder}
                leftResultsPlaceHolder={props.leftResultsPlaceHolder}
                filterRightPlaceHolder={<FilterPaused visible={model.dataGrid?.filterDisabled} onResume={model.resumeFilter} />}
                export={model.exportBlocked ? 'unavailable' : model.export}
                rowIdProvider={model.gridRowIdProvider}
                onRenderColumnSelector={model.handleRenderColumnSelector}
                renderRowSelector={props.renderRowSelector ?? undefined}
                // renderColumnGroup={(group) => (group === 'Tags' && props.allowAutoAddTags ? <TagColumnGroup model={model} /> : null)}
            />
            <ResourcesGridDetailDrawer model={model} gridTitle={props.title} />
        </>
    );
}

function TagColumnGroup({ model }: { model: ResourceGridModel }) {
    const showingAll = useEventValue(model.showingAllTags);
    const { ref, hovered } = useHover();
    return (
        <div>
            <Popover shadow="0px 5px 5px #ccc" withArrow withinPortal opened={hovered} offset={0}>
                <Popover.Target>
                    <span ref={ref}>
                        Tags
                        <Switch
                            ml="xs"
                            sx={{ display: 'inline-block' }}
                            size="xs"
                            checked={showingAll}
                            onChange={(c) => model.setAndShowTags(c.target.checked)}
                        />
                    </span>
                </Popover.Target>
                <Popover.Dropdown>
                    <Text>Auto-Add Tag Columns: {model.showingAllTags.value ? 'On' : 'Off'}</Text>
                    <Text size="sm" sx={{ width: '250px' }}>
                        Columns for tags can be automatically added and removed
                    </Text>
                </Popover.Dropdown>
            </Popover>
        </div>
    );
}

function FilterPaused({ onResume, visible }: { onResume: () => void; visible?: EventEmitter<boolean> }) {
    const show = useEventValue(visible);
    const [tooltipOpen, { close, open }] = useDisclosure(true);
    useEffect(() => {
        if (show) {
            open();
        }
    }, [show]);
    return !show ? null : (
        <>
            <Button
                sx={{ height: 30 }}
                radius="lg"
                onClick={onResume}
                variant="default"
                rightIcon={<PlayerSkipForward size={12} />}
                data-atid="ResumeFilteringButton"
            >
                Resume Filtering
            </Button>
        </>
    );
}
